.team-page-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  min-height: 1px;
  position: relative;
}

.team-page-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
}

.team-page-content-left {
  width: 258px;
  margin-left: 0;
  margin-right: 0;
}

.team-content-left-description-container {
  margin: 32px auto;
}
.team-content-left-description-header {
  align-items: center;
  border-bottom: 2px solid #e8ecee;
  display: flex;
  height: 32px;
  margin-left: 24px;
  margin-right: 24px;
  padding: 8px 0;
  position: relative;
}
.team-content-left-description-form {
  padding: 4px;
  margin: 16px 20px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  border-width: 1px;
  box-sizing: border-box;
  border-radius: 6px;
}

.edit-description {
  border-color: transparent;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  outline: none;
  background-color: transparent;
  min-width: 194px;
  min-height: 83px;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #838485;
}
.description:focus {
  border-color: #cbd4db;
}
.team-content-left-members-container {
  margin: 32px auto;
  margin-top: 0;
}
.team-content-left-members-header {
  align-items: center;
  border-bottom: 2px solid #e8ecee;
  display: flex;
  height: 32px;
  margin-left: 24px;
  margin-right: 24px;
  padding: 8px 0;
  position: relative;
  justify-content: space-between;
}
.team-content-left-members--list {
  margin: 5px 24px;
}

.team-page-content-right {
  margin-left: 0;
  margin-right: 0;
  margin-top: 32px;
  width: 672px;
  height: 96%;
}
.team-content-right-header {
  align-items: center;
  border-bottom: 2px solid #e8ecee;
  display: flex;
  height: 32px;
  margin-left: 24px;
  margin-right: 24px;
  padding: 8px 0;
  position: relative;
}

.team-content-right-projects--list {
  display: flex;
  flex-flow: row wrap;
  padding-top: 16px;
  /* height: 100%; */
  width: 100%;
  overflow-y: scroll;
}
.team-content-right-projects--list::-webkit-scrollbar {
  display: none;
}
.team-content-title {
  box-sizing: border-box;
  color: #151b26;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

/* Team Member Icon */

.team-member-container {
  display: flex;
  padding: 8px 0;
}

.team-member-icon {
}

.team-member-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  font-size: 13px;
}
.team-member-name {
}
.team-member-email {
}

.team-member-container:hover .new-user-avatar {
  border: dashed #91979c;
}

.team-member-container:hover .new-user-avatar--icon {
  color: #91979c;
}

.new-team-member-name {
  color: #9ca6af;
}
.team-member-container:hover .new-team-member-name {
  color: #5b5e61;
}
