.project-tile-container {
  border-radius: 30px;
  box-sizing: border-box;
  height: 170px;
  min-width: 152px;
  padding: 16px 0;
  width: 152px;
  align-items: center;
  /* background: rgba(246, 248, 249, 0); */
  display: flex;
  flex-flow: column;
  position: relative;
  transition-duration: 0.4s;
  cursor: pointer;
}
.project-tile-icon-1 {
  background: lightblue;
}
.project-tile-icon-2 {
  background: yellowgreen;
}
.project-tile-icon-3 {
  background: red;
}
.project-tile-icon-4 {
  background: salmon;
}
.project-tile-icon-5 {
  background: seagreen;
}
.project-tile-icon-6 {
  background: darkcyan;
}
.project-tile-icon-7 {
  background: slateblue;
}
.project-tile-icon-8 {
  background: skyblue;
}
.project-tile-icon-9 {
  background: lightgoldenrodyellow;
}
.project-tile-icon-10 {
  background: lawngreen;
}
.project-tile-icon-11 {
  background: lightblue;
}
.project-tile-icon-12 {
  background: yellowgreen;
}
.project-tile-icon-13 {
  background: red;
}
.project-tile-icon-14 {
  background: salmon;
}
.project-tile-icon-15 {
  background: seagreen;
}
.project-tile-icon-16 {
  background: darkcyan;
}
.project-tile-icon-17 {
  background: slateblue;
}
.project-tile-icon-18 {
  background: skyblue;
}
.project-tile-icon-19 {
  background: lightgoldenrodyellow;
}
.project-tile-icon-20 {
  background: lawngreen;
}
.project-tile-icon-21 {
  background: lightblue;
}
.project-tile-icon-22 {
  background: yellowgreen;
}
.project-tile-icon-23 {
  background: red;
}
.project-tile-icon-24 {
  background: salmon;
}
.project-tile-icon-25 {
  background: seagreen;
}
.project-tile-icon-26 {
  background: darkcyan;
}
.project-tile-icon-27 {
  background: slateblue;
}
.project-tile-icon-28 {
  background: skyblue;
}
.project-tile-icon-29 {
  background: lightgoldenrodyellow;
}
.project-tile-icon-30 {
  background: lawngreen;
}
.project-tile-icon-31 {
  background: lightblue;
}
.project-tile-icon-32 {
  background: yellowgreen;
}
.project-tile-icon-33 {
  background: red;
}
.project-tile-icon-34 {
  background: salmon;
}
.project-tile-icon-35 {
  background: seagreen;
}
.project-tile-icon-36 {
  background: darkcyan;
}
.project-tile-icon-37 {
  background: slateblue;
}
.project-tile-icon-38 {
  background: skyblue;
}
.project-tile-icon-39 {
  background: lightgoldenrodyellow;
}
.project-tile-icon-40 {
  background: lawngreen;
}
.project-tile-container:hover {
  padding: 0px 0px;
}

.project-tile-box {
  height: 100px;
  width: 100px;
}

.project-tile--link {
  text-decoration: none;
  height: 25%;
}

.project-tile--link:visited {
  color: inherit;
}

.project-tile-icon {
  border-radius: 20px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 -2px rgba(0, 0, 0, 0.05);
  align-items: center;

  display: flex;
  fill: #fff;
  flex-direction: column;
  justify-content: center;
  flex-flow: column;
  position: relative;
}

.new-project-tile-icon-container {
  border-radius: 20px;
  height: 100%;
  width: 100%;
  border: dashed #cbd4db;
  align-items: center;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-tile-container:hover .new-project-tile-icon-container {
  border: dashed #91979c;
}

.new-project-tile-icon {
  font-size: 40px;
  color: #cbd4db;
}

.project-tile-container:hover .new-project-tile-icon {
  color: #91979c;
}

.project-tile-name {
  padding-top: 10px;
  color: black;
  font-size: 15px;
}

.project-home-item {
  /* display: flex;
  justify-content: space-between; */
  border-bottom: 1px solid #e8ecee;
  background-color: white;
  /* font-size: 13px; */
  width: 300px;
  height: 75px;
  padding: 5px;
  margin-bottom: 15px;
  box-shadow: 2px 2px 6px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.project-home-item:hover {
  background-color: rgba(133, 140, 155, 0.08);
}

.project-home-item-name-container {
  display: flex;
  flex-direction: column;
  width: 150px;
  text-overflow: ellipsis;
  margin-left: 25px;
}

.project-home-item-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.project-home-item-inner-left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 500px;
}

.project-page-container {
  display: flex;
  padding: 20px 15px 15px 15px;
  align-items: center;
  box-sizing: border-box;
  height: 89vh;
  width: 95%;
  /* overflow: scroll; */
}

.project-page-main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%
}
.project-page-main-content {
  display: contents;
}

.project-container {
  display: flex;
  /* padding: 15px; */
  /* margin: 30px 15px; */
  height: 100%;
  width: 100%;
  overflow: auto;
}
