.tasklist-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  /* width: 270px; */
  margin: 0px 10px;
  padding: 0px 10px;
  align-items: center;
  /* background-color: #ebecf0; */
  background-color: white;
  border-radius: 10px;
  height: fit-content;
}

.tasklist-header {
  display: flex;
  margin: 10px 0px;
  width: inherit;
}
.tasklist-title {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  width: inherit;
  align-content: center;
  justify-content: center;
}
.tasklist-title__textarea {
  resize: none;
  font-size: 16px;
  line-height: 16px;
  height: 18px;
  outline: none;
  border: 1px solid lightgray;
}

.tasklist-more-menu {
  display: flex;
  cursor: pointer;
}
.tasklist-task--list {
  width: 290px;
  min-height: 25px;
  /* width: 260px; */
  display: flex;
  flex-direction: column;
}

.tasklist-new-task--button {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 15px;
  font-size: 15px;
  margin: 10px 0;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  transition: top ease 0.2s;
}

.tasklist-new-task--button:hover {
  background: #e8ecee;
}

.tasklist-new-tasklist--button {
  display: flex;
  width: 50px;
  margin: 0px 10px;
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  cursor: pointer;
}

.tasklist-new-tasklist--button:hover {
  background: #dcdde0;
}
