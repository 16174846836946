.tasks-container {
  /* background-color: #f6f8f9; */
  display: flex;
  /* flex-direction: column; */
  /* padding: 15px; */
  padding: 20px 15px 15px 15px;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}

.tasks-inner-container {
  background-color: white;
  /* min-width: 405px; */
  /* max-width: 1000px; */
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 2px solid #e8ecee;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.08);
}
.tasks-inner-container.hidden {
  background-color: white;
  /* min-width: 405px; */
  /* max-width: 1000px; */
  opacity: 0;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 2px solid #e8ecee;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.08);
}

.tasks-inner-container::-webkit-scrollbar {
  display: none;
}

.tasks-main-content {
  display: contents;
}
.tasks-container-header {
  display: flex;
  max-width: 1000px;
  width: 100%;
  justify-content: space-between;
}
.tasks-add-task-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.tasks-add-task-icon svg {
  height: 20px;
  width: 25px;
}

.add-task-button {
  /* background: #14aaf5; */
  font-size: 13px;
  /* border: none; */
  /* border-radius: 3px; */
  height: 25px;
  /* color: white; */
  outline: none;
}

.task-home-item {
  /* display: flex;
  justify-content: space-between; */
  border-bottom: 1px solid #e8ecee;
  background-color: white;
  /* font-size: 13px; */
  width: 300px;
  height: 75px;
  padding: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 23px 4px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 16px;
}
.task-home-item:hover {
  background-color: rgba(133, 140, 155, -1.92);
}

.task-project-item {
  /* background: white; */
  background-color: #f7f8fa;
  /* background-color: rgba(240, 237, 237, 0.5); */
  display: flex;
  padding: 6px 8px 2px;
  font-size: 13px;
  /* margin: 10px 0; */
  margin-bottom: 8px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  height: 90px;
  transition: 0.2s;
}
.task-project-item:hover {
  background: #e8ecee;
}

.task-project-container-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.task-project-name {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 150px;
}

.task-project-icons {
  display: flex;
  margin-left: -48px;
  justify-content: space-evenly;
}

.task-project-container-right {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  align-items: flex-end;
  justify-content: space-evenly;
  /* width: 95px; */
  width: 100px;
}

.task-home-item-name-container {
  display: flex;
  flex-direction: column;
  width: 150px;
  text-overflow: ellipsis;
  margin-left: 25px;
  margin-right: 63px;
}

.task-home-item-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.task-home-item-inner-left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 500px;
}

.task-section > div {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.task-section-header {
  display: flex;
  /* border-top: 1px solid #e8ecee; */
}

.task-section-list {
}

.open-arrow-collapse {
  align-self: center;
  padding: 0 5px;
  cursor: pointer;
}
.open-arrow-collapse:hover {
  color: blue;
}
.open-arrow {
  align-self: center;
  padding: 0 5px;
  transform: rotate(90deg);
  cursor: pointer;
}
.open-arrow:hover {
  color: blue;
}

.task-section-title {
  margin: 5px 0;
}

.task-task-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8ecee;
  font-size: 13px;
  height: 40px;
  padding: 0px 5px;
  cursor: pointer;
  align-items: center;
}

.task-task-item:hover {
  background-color: rgba(133, 140, 155, 0.08);
}

.task-project-home-name-container {
  border-radius: 20px;
  width: 140px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
}

/* Task Details Form */
.task-details-form {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}

.edit-task-title {
  line-height: 32px;
  height: 42px;
  font-weight: 500;
  font-size: 24px;
  width: 100%;
  padding: 4px 0px 0px 0px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c5cad1;
}
.edit-task-title:focus,
.edit-task-title:hover,
.edit-task-description:focus,
.edit-task-description:hover {
  border: 2px solid #bbbdc0;
}

.textarea {
  box-sizing: border-box;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  outline: none;
  padding-left: 10px;
}
.edit-task-description {
  margin-top: 10px;
  height: 200px;
  border-radius: 5px;
  border: 1px solid #c5cad1;
  width: 100%;
  color: #151b26;
  font-size: 13px;
}

.edit-task-button {
  cursor: pointer;
  background: #0093ff;
  text-align: center;
  padding: 0 9px;
  color: white;
  font-size: 13px;
  box-sizing: border-box;
  height: 30px;
  border-radius: 4px;

  border: none;
  margin-left: 500px;
  margin-top: 10px;
}

.edit-task-info {
  display: flex;
  justify-content: space-between;
}

.edit-task-form-input {
  padding: 10px 5px;
  width: 100%;
  border: 1px solid lightgray;
  outline: none;
  margin: 10px 0;
  text-overflow: ellipsis;
}

/* PopOut Task menu */

.task-detail-menu.active {
  background-color: white;
  width: 60%;
  height: 100%;
  position: fixed;
  /* display: flex; */
  box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.08);
  border: 2px solid #e8ecee;
  padding: 10px;
  margin: 10px 10px 0px 0px;
  /* margin: 10px; */
  /* right: 0; */
  /* transition: right 10000ms; */
  /* transition-timing-function: ease-in-out 10000ms; */
  /* transition: left 2s ease-out position 2s ease-out; */
}
.task-detail-menu.collapsed {
  background-color: white;
  display: flex;
  justify-content: center;
  position: fixed;
  right: -100%;
  /* transition: right 10000ms;
  transition-timing-function: ease-in-out 10000ms; */
}

.task-detail-menu-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 1px;
}

.task-detail-menu-top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

.task-detail-menu-main-content {
  /* height: 40%; */
  /* height: 55%; */
  /* padding-left: 30px; */
}

.task-detail-close-icon {
  align-self: flex-end;
}

.mark-complete-container__incompleted:hover .complete-button__incompleted {
  border: 1px solid lightgreen;
}
.mark-complete-container__incompleted:hover .check-mark-icon__incompleted {
  color: lightgreen;
}
.mark-complete-container__incompleted:hover .mark-complete__incompleted {
  color: lightgreen;
}
.mark-complete-container__completed:hover .complete-button__completed {
  border: 1px solid #cbd4db;
}
.mark-complete-container__completed:hover .check-mark-icon__completed {
  color: #cbd4db;
}
.mark-complete-container__completed:hover .mark-complete__completed {
  color: #cbd4db;
}

.complete-button__completed {
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  display: flex;
  border: 1px solid lightgreen;
  cursor: pointer;
}
.check-mark-container {
  margin: 0px 5px;
}
.check-mark-icon__completed {
  color: lightgreen;
}
.mark-complete__completed {
  margin-right: 5px;
  color: lightgreen;
}

.complete-button__incompleted {
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  display: flex;
  border: 1px solid #cbd4db;
  cursor: pointer;
}

.check-mark-icon__incompleted {
  color: #cbd4db;
}
.mark-complete__incompleted {
  margin-right: 5px;
  color: #cbd4db;
}

.task-detail-title {
}

.task-details-container {
  display: flex;
}
.task-details-container p {
  font-size: 13px;
  margin-bottom: 25px;
}
.task-details-subtitles > p {
  color: gray;
}
.task-details-data {
  margin-left: 25px;
  margin-top: 10px;
  width: 100%;
}
.task-details-data > p {
  font-weight: 500;
}
.task-detail-description-container {
  padding: 4px;
  margin: 16px 20px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  border-width: 1px;
  box-sizing: border-box;
  border-radius: 6px;
  /* height: 150px; */
}
.task-detail-description-container:hover,
.task-detail-description-container:focus {
  border: 1px solid lightgray;
}
.task-detail-edit-description {
  border-color: transparent;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  outline: none;
  background-color: transparent;
  min-width: 194px;
  min-height: 83px;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #838485;
  width: 100%;
  height: 100%;
}
.react-datepicker-ignore-onclickoutside {
  border: none;
}
.react-datepicker__input-container input {
  border: none;
}
.task-detail-user-comments-container {
  margin: -10px;
  /* height: 59%; */
  /* height: 44%; */
  height: 250px;
  /* background-color: rgba(21, 27, 38, 0.02); */
  border-top: 1px solid lightgray;
  /* overflow-y: scroll; */
}
.task-detail-user-comments-container::-webkit-scrollbar {
  display: none;
}

.task-detail-comment-container {
  align-items: flex-end;
  /* margin: 0px -10px -10px; */
  /* height: 9%; */
  background-color: rgba(21, 27, 38, 0.08);
  display: flex;
}

.task-detail-comment-container.active {
  height: 25%;
  display: flex;
  align-items: flex-start;
  transition: 1000ms;
}

.task-detail-user-comment {
  display: flex;
  margin-left: 40px;
  margin-top: 20px;
  width: 88%;
  margin-bottom: 10px;
}
.task-detail-user-comment.active {
  height: 80%;
  transition: 1000ms;
}
.task-detail-comment-avatar {
}
.task-detail-comment-box {
  width: 90%;
}
.task-detail-comment-form {
  padding: 4px;
  margin-left: 15px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  border-width: 1px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid gray;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.comment-textarea {
  border-color: transparent;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  outline: none;
  background-color: transparent;
  width: 99%;
  min-height: 100%;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #838485;
}
.comment-textarea::-webkit-scrollbar {
  display: none;
}
.comment-button {
  height: 30px;
  width: 80px;
  color: white;
  background-color: #0093ff;
  border-radius: 6px;
  border: none;
}

/* User Comments */
.comment-container {
  margin-top: 15px;
  margin-left: 10px;
}

.comment-header {
  display: flex;
  align-items: center;
}

.comment-text {
  margin-left: 35px;
}

.dot-task-1 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  /* background: lightblue; */
  background-color: rgba(176, 216, 230, 0.8);
}
.dot-task-2 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(154, 205, 50, 0.8);
}
.dot-task-3 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(255, 0, 0, 0.8);
}
.dot-task-4 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(250, 128, 114, 0.8);
}
.dot-task-5 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(46, 139, 87, 0.8);
}
.dot-task-6 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(0, 139, 139, 0.8);
}
.dot-task-7 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(106, 90, 205, 0.8);
}
.dot-task-8 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(135, 206, 235, 0.8);
}
.dot-task-9 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(250, 250, 210, 0.8);
}
.dot-task-10 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(124, 252, 0, 0.8);
}
.dot-task-11 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  /* background: lightblue; */
  background-color: rgba(176, 216, 230, 0.8);
}
.dot-task-12 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(154, 205, 50, 0.8);
}
.dot-task-13 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(255, 0, 0, 0.8);
}
.dot-task-14 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(250, 128, 114, 0.8);
}
.dot-task-15 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(46, 139, 87, 0.8);
}
.dot-task-16 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(0, 139, 139, 0.8);
}
.dot-task-17 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(106, 90, 205, 0.8);
}
.dot-task-18 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(135, 206, 235, 0.8);
}
.dot-task-19 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(250, 250, 210, 0.8);
}
.dot-task-20 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(124, 252, 0, 0.8);
}
.dot-task-21 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  /* background: lightblue; */
  background-color: rgba(176, 216, 230, 0.8);
}
.dot-task-22 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(154, 205, 50, 0.8);
}
.dot-task-23 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(255, 0, 0, 0.8);
}
.dot-task-24 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(250, 128, 114, 0.8);
}
.dot-task-25 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(46, 139, 87, 0.8);
}
.dot-task-26 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(0, 139, 139, 0.8);
}
.dot-task-27 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(106, 90, 205, 0.8);
}
.dot-task-28 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(135, 206, 235, 0.8);
}
.dot-task-29 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(250, 250, 210, 0.8);
}
.dot-task-30 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(124, 252, 0, 0.8);
}
.dot-task-31 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  /* background: lightblue; */
  background-color: rgba(176, 216, 230, 0.8);
}
.dot-task-32 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(154, 205, 50, 0.8);
}
.dot-task-33 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(255, 0, 0, 0.8);
}
.dot-task-34 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(250, 128, 114, 0.8);
}
.dot-task-35 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(46, 139, 87, 0.8);
}
.dot-task-36 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(0, 139, 139, 0.8);
}
.dot-task-37 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(106, 90, 205, 0.8);
}
.dot-task-38 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(135, 206, 235, 0.8);
}
.dot-task-39 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(250, 250, 210, 0.8);
}
.dot-task-40 {
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  background-color: rgba(124, 252, 0, 0.8);
}

.task-project-1 {
  /* background: lightblue; */
  background-color: rgba(176, 216, 230, 0.8);
}

.task-project-2 {
  background-color: rgba(154, 205, 50, 0.8);
}
.task-project-3 {
  /* background: red; */
  background-color: rgba(255, 0, 0, 0.8);
}
.task-project-4 {
  /* background: salmon; */
  background-color: rgba(250, 128, 114, 0.8);
}
.task-project-5 {
  /* background: seagreen; */
  background-color: rgba(46, 139, 87, 0.8);
}
.task-project-6 {
  /* background: darkcyan; */
  background-color: rgba(0, 139, 139, 0.8);
}
.task-project-7 {
  /* background: slateblue;  */
  background-color: rgba(106, 90, 205, 0.8);
}
.task-project-8 {
  /* background: skyblue; */
  background-color: rgba(135, 206, 235, 0.8);
}
.task-project-9 {
  /* background: lightgoldenrodyellow; */
  background-color: rgba(250, 250, 210, 0.8);
}
.task-project-10 {
  /* background: lawngreen; */
  background-color: rgba(124, 252, 0, 0.8);
}
.task-project-11 {
  /* background: lightblue; */
  background-color: rgba(176, 216, 230, 0.8);
}

.task-project-12 {
  background-color: rgba(154, 205, 50, 0.8);
}
.task-project-13 {
  /* background: red; */
  background-color: rgba(255, 0, 0, 0.8);
}
.task-project-14 {
  /* background: salmon; */
  background-color: rgba(250, 128, 114, 0.8);
}
.task-project-15 {
  /* background: seagreen; */
  background-color: rgba(46, 139, 87, 0.8);
}
.task-project-16 {
  /* background: darkcyan; */
  background-color: rgba(0, 139, 139, 0.8);
}
.task-project-17 {
  /* background: slateblue;  */
  background-color: rgba(106, 90, 205, 0.8);
}
.task-project-18 {
  /* background: skyblue; */
  background-color: rgba(135, 206, 235, 0.8);
}
.task-project-19 {
  /* background: lightgoldenrodyellow; */
  background-color: rgba(250, 250, 210, 0.8);
}
.task-project-20 {
  /* background: lawngreen; */
  background-color: rgba(124, 252, 0, 0.8);
}
.task-project-21 {
  /* background: lightblue; */
  background-color: rgba(176, 216, 230, 0.8);
}

.task-project-22 {
  background-color: rgba(154, 205, 50, 0.8);
}
.task-project-23 {
  /* background: red; */
  background-color: rgba(255, 0, 0, 0.8);
}
.task-project-24 {
  /* background: salmon; */
  background-color: rgba(250, 128, 114, 0.8);
}
.task-project-25 {
  /* background: seagreen; */
  background-color: rgba(46, 139, 87, 0.8);
}
.task-project-26 {
  /* background: darkcyan; */
  background-color: rgba(0, 139, 139, 0.8);
}
.task-project-27 {
  /* background: slateblue;  */
  background-color: rgba(106, 90, 205, 0.8);
}
.task-project-28 {
  /* background: skyblue; */
  background-color: rgba(135, 206, 235, 0.8);
}
.task-project-29 {
  /* background: lightgoldenrodyellow; */
  background-color: rgba(250, 250, 210, 0.8);
}
.task-project-30 {
  /* background: lawngreen; */
  background-color: rgba(124, 252, 0, 0.8);
}
.task-project-31 {
  /* background: lightblue; */
  background-color: rgba(176, 216, 230, 0.8);
}

.task-project-32 {
  background-color: rgba(154, 205, 50, 0.8);
}
.task-project-33 {
  /* background: red; */
  background-color: rgba(255, 0, 0, 0.8);
}
.task-project-34 {
  /* background: salmon; */
  background-color: rgba(250, 128, 114, 0.8);
}
.task-project-35 {
  /* background: seagreen; */
  background-color: rgba(46, 139, 87, 0.8);
}
.task-project-36 {
  /* background: darkcyan; */
  background-color: rgba(0, 139, 139, 0.8);
}
.task-project-37 {
  /* background: slateblue;  */
  background-color: rgba(106, 90, 205, 0.8);
}
.task-project-38 {
  /* background: skyblue; */
  background-color: rgba(135, 206, 235, 0.8);
}
.task-project-39 {
  /* background: lightgoldenrodyellow; */
  background-color: rgba(250, 250, 210, 0.8);
}
.task-project-40 {
  /* background: lawngreen; */
  background-color: rgba(124, 252, 0, 0.8);
}
