.popout-form {
  background-color: white;
  width: 60%;
  /* height: 100%; */
  /* display: flex; */
  box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.08);
  border: 2px solid #e8ecee;
  padding: 10px;
  margin: 10px;
  position: fixed;
}

.popout-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 1px;
}

.popout-form-top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

.popout-form-close-icon {
}
