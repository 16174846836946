.form-container {
  width: 100%;
}

.form-header {
  margin: 0;
}

.textarea {
  box-sizing: border-box;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  outline: none;
}
.form-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
}

.form-section {
  flex-direction: column;
  margin-bottom: 48px;
  margin-top: 32px;
  display: flex;
  flex: 1 1 auto;
  min-width: 1px;
  margin-right: 10px;
}
.input-section {
  margin: 10px;
}
.label-container {
  box-sizing: border-box;
  cursor: default;
  display: inline-flex;
  flex: 0 0 auto;
  margin-bottom: 8px;
}

.input-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 1px;
}

.form-input {
  /* padding: 10px 5px; */
  /* width: 100%; */
  /* border: none;
  outline: none;
  text-overflow: ellipsis; */
  background-color: #f6f8f9;
  border-color: #cbd4db;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 1px;
  font-size: 16px;
  outline: none;
  padding-left: 4px;
  height: 36px;
  padding-bottom: 4px;
  padding-top: 4px;
}

.form-input:hover,
.form-input:focus {
  border: 1px solid lightgray;
}
.name-textarea {
  line-height: 32px;
  height: 42px;
  font-weight: 500;
  font-size: 24px;
  width: 100%;
  padding: 4px 0px 0px 0px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c5cad1;
}

.form-label {
  /* display: flex;
  flex-direction: column; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #6f7782;
  display: inline-block;
  font-weight: 500;
  margin-right: 8px;
}

.error-message {
  color: red;
  margin: 1px;
  font-size: 13px;
  width: 200px;
}

.form-button-container {
  display: flex;
  justify-content: flex-end;
}

.cancel-button {
  align-items: center;
  border: 1px solid;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  overflow: hidden;
  transition-duration: 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
  height: 36px;
  line-height: 36px;
  padding: 0 12px;
  background: #f6f8f9;
  border-color: #e8ecee;
  color: #9ca6af;
  fill: #9ca6af;
  margin-right: 15px;
  cursor: pointer;
}
.submit-button.disabled {
  align-items: center;
  border: 1px solid;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  overflow: hidden;
  transition-duration: 0.2s;
  /* transition-property: background,border,box-shadow,color,fill; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
  height: 36px;
  line-height: 36px;
  padding: 0 12px;
  background: #f6f8f9;
  border-color: #e8ecee;
  color: #9ca6af;
  fill: #9ca6af;
}
.submit-button.enabled {
  align-items: center;
  border: 1px solid;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  overflow: hidden;
  transition-duration: 0.2s;
  /* transition-property: background,border,box-shadow,color,fill; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
  height: 36px;
  line-height: 36px;
  padding: 0 12px;
  background: #14aaf5;
  border-color: #14aaf5;
  color: #fff;
  fill: #fff;
  cursor: pointer;
}
