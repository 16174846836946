.modal-container {
  position: absolute;

  background-color: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 16px 32px 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: 4px;
}

.tasklist-modal-container {
  position: absolute;

  background-color: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 16px 32px 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: 4px;
}
/* [role="presentation"] {
  background-color: white !important;
} */
